import React from 'react'
import styled from 'styled-components'
import { RegisterButton } from '../../UI'

const Wrapper = styled.div`
    margin: 1rem;
    padding: 1rem;
    margin-bottom: 3.5rem;
    background: ${(props) => props.theme.color.tellowPurple};
    height: 19.5rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
`
const Title = styled.h1`
    margin-bottom: 2rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    letter-spacing: -0.025em;
    color: ${(props) => props.theme.color.tellowWhite};
`
const SignupButton = styled(RegisterButton)`
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: normal;
    word-break: normal;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.color.tellowPurple};
    background-color: ${(props) => props.theme.color.tellowWhite};
    padding: 18px 28px;
    @media (max-width: 768px) {
        padding: 10px 38px;
    }
`

const TryForFree = ({ data }) => (
    <Wrapper>
        <Title>{data.title}</Title>
        <SignupButton>
            {data.textCTA} <span className="arrow">{'->'}</span>
        </SignupButton>
    </Wrapper>
)
export default TryForFree
