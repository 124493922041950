import React from 'react'
import styled from 'styled-components'
import Desktop from '../../../images/btw-aangifte/desktop.js'
import { Button } from '../../UI'

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2.5rem;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin: 1rem;
        gap: 1rem;
    }
`
const ContentContainer = styled.div`
    margin: 0rem 1rem 0rem 1rem;
`
const Image = styled.img`
    margin: 1rem;
`
const DesktopTitle = styled.h1`
    margin-bottom: 1rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    letter-spacing: -0.025em;
    align-items: flex-start;
    color: ${(props) => props.theme.color.tellowPurple};

    @media (max-width: 768px) {
        display: none;
    }
`

const MobileTitle = styled.h1`
    display: none;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: 1.875rem;
        line-height: 2.25rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 600;
        letter-spacing: -0.025em;
        align-items: flex-start;
        color: ${(props) => props.theme.color.tellowPurple};
        display: block;
    }
`
const Text = styled.p`
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    align-items: flex-start;
`

const WebinarButton = styled(Button)`
    align-items: center;
    gap: 0.3rem;
    font-size: 0.8rem;
    padding: 18px 28px;
    text-align: center;
    max-width: 24rem;
    @media (max-width: 768px) {
        padding: 10px 38px;
    }
`
const Icon = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const HelpCenterButton = styled(Button)`
    padding: 18px 28px;
    @media (max-width: 768px) {
        padding: 10px 38px;
        text-align: center;
    }
`
const ButtonContainer = styled.div`
    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
    }
`

const GettingStarted = ({ data, showWebinatButton }) => (
    <MainContainer>
        <MobileTitle>{data.title}</MobileTitle>
        <Image src={data.img} alt="Getting-started" />
        <ContentContainer>
            <DesktopTitle>{data.title}</DesktopTitle>
            <Text>{data.text}</Text>
            <ButtonContainer>
                {showWebinatButton ? (
                    <WebinarButton href={data.url}>
                        <Icon>
                            <Desktop />
                        </Icon>
                        {data.textCTA}
                    </WebinarButton>
                ) : (
                    <HelpCenterButton href={data.url}> {data.textCTA}</HelpCenterButton>
                )}
            </ButtonContainer>
        </ContentContainer>
    </MainContainer>
)
export default GettingStarted
