import React from 'react'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import { RelativeContainer, ComponentContainer } from '../components/shared/landingPages/layoutComponents'
import SupportHeader from '../components/pages/support/supportHeader'
import TopHighlights from '../components/shared/landingPages/topHighlights'
import SupportBackground from '../images/support/supportbg.png'
import Phone from '../images/icons/phone.js'
import Chat from '../images/icons/chat.js'
import Email from '../images/icons/email.js'
import Office from '../images/icons/office.js'
import WebinarImage from '../images/support/webinar.png'
import GettingStarted from '../components/pages/support/gettingStarted'
import PersonalBookkeeper from '../components/pages/support/personalBookkeeper'
import BookkeeperImage from '../images/support/personal-bookkeeper.png'
import EasyToUseImage from '../images/support/easy-to-use.png'
import TryForFree from '../components/pages/support/tryForFree'

const supportHeaderData = {
    title: 'Nummer 1 klantenservice van Nederland',
    text: 'Het Tellow support team wordt met een 9+ beoordeeld en geeft </br> de beste ondersteuning van alle boekhoudpakketten in Nederland.',
    headerImg: SupportBackground,
    textCTA: `Gebruik Tellow gratis`,
}

const topHighlightData = {
    title: `Hoe kun je ons bereiken?`,
    subtitle: `Met de snelste reactietijd van Nederland sta je er nooit alleen voor. Op werkdagen zijn we bereikbaar van 09:00 tot 21:00 en kun je direct spreken met je eigen boekhouder.`,
    endText: `Bepaalde abonnementen, zoals het Gratis abonnement, hebben beperkingen op contactvorm en/of direct contact met boekhouders. Lees hier alles over op onze <u><a href="/prijs/">abonnementen</a></u> pagina.`,
    points: [
        {
            Icon: Phone,
            title: `Telefoon`,
            body: `
            Ons team staat voor je klaar op werkdagen tussen 09:00 en 21:00 uur. Dus ook in de avond! Je kunt een belafspraak inplannen of ons direct bellen.
        `,
        },
        {
            Icon: Chat,
            title: `Chat`,
            body: `
            Ontvang direct vanuit je mobiele app of browser. Via de chat zien onze medewerkers precies waar je hulp nodig hebt en kunnen we het snel oplossen.
        `,
        },
        {
            Icon: Email,
            title: `Email`,
            body: `
            Als je voorkeur uitgaat om je vraag op te schrijven of iets door te sturen dan kan dat, onze medewerkers reageren meestal binnen een uur op je email.
        `,
        },
        {
            Icon: Office,
            title: `Op locatie`,
            body: `
            Je kunt ons op afspraak bezoeken in Amsterdam, we zijn goed bereikbaar met het openbaar vervoer, de auto of de fiets als je in Amsterdam woont.
        `,
        },
    ],
}

const GettingStartedData = {
    img: WebinarImage,
    title: `Wegwijs worden in Tellow`,
    text: `Wil je zelf ervaren hoe Tellow werkt? Bekijk de webinar en kijk rustig mee hoe het werkt. Het duurt ongeveer een kwartiertje en als je wil kun je direct aan de slag.`,
    textCTA: `Bekijk direct`,
    url: `https://help.tellow.nl/nl/articles/8575410-starten-met-tellow-video`,
}

const PersonalBookkeeperData = {
    title: 'Tellow met je eigen boekhouder',
    list: [
        `Al je zakelijke en privébelastingen geregeld voor een vaste prijs, geen extra kosten of betalen per uur voor vragen`,
        `Advies op maat voor je bedrijf en administratie, zoek samen met je boekhouder naar besparingen en verbeteringen`,
        `Opstellen en controles op je btw-aangiften en de aangifte inkomstenbelasting, als je fiscale partner in loondienst is krijg je die er gratis bij`,
    ],
    personalBookkeeperImage: BookkeeperImage,
    textCTA: 'Ontdek Tellow Compleet',
}

const EasyToUseData = {
    title: 'Makkelijk in gebruik',
    text: 'Tellow is ontwikkeld om zo makkelijk mogelijk te zijn in het gebruik. Om te zien hoe je bepaalde taken kan uitvoeren of om een antwoord te vinden op veelgestelde vragen kun je een kijkje nemen in onze hulpartikelen.',
    img: EasyToUseImage,
    textCTA: `Bezoek ons helpcentrum`,
    url: `https://help.tellow.nl/`,
}

const TryForFreeData = {
    title: `Nooit meer onzeker over je boekhouding en financiën`,
    textCTA: `Probeer Tellow gratis`,
}

const Support = () => (
    <Layout>
        <Meta
            keywords={['boekhouder', 'zzp', 'freelancer', 'ondernemer', 'eenmanszaak', 'administratie', 'boekhouden', 'boekhoudpakket']}
            path="/support"
            title="Onbeperkte ondersteuning | Tellow.nl"
            description="Ons team staat voor je klaar om je te helpen op alle werkdagen, ook in de avond! Via chat, telefoon en op locatie bereikbaar. Je kunt een belafspraak inplannen of ons direct bellen"
        />

        <SupportHeader data={supportHeaderData} />

        <RelativeContainer>
            <ComponentContainer>
                <TopHighlights data={topHighlightData} headerTextPurple />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <ComponentContainer>
                <GettingStarted data={GettingStartedData} showWebinatButton />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <ComponentContainer>
                <PersonalBookkeeper data={PersonalBookkeeperData} />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <ComponentContainer>
                <GettingStarted data={EasyToUseData} />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <TryForFree data={TryForFreeData} />
        </RelativeContainer>
    </Layout>
)
export default Support
