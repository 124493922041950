import React from 'react'
import styled from 'styled-components'
import { RegisterButton } from '../../UI'
import GridBackground from './gridBackground'

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.4);
    color: ${(props) => props.theme.color.tellowWhite};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`
const ContentContainer = styled.div`
    margin: 1rem;
    align-items: center;
    text-align: center;
`
const Text = styled.p`
    margin-bottom: 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    opacity: 0.9;
`

const Title = styled.h1`
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 2.3rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    letter-spacing: -0.025em;
    @media (max-width: 768px) {
        font-size: 2rem;
    }
`
const SignupButton = styled(RegisterButton)`
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: normal;
    word-break: normal;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.color.tellowPurple};
    background-color: ${(props) => props.theme.color.tellowWhite};
    padding: 18px 28px;
    @media (max-width: 768px) {
        padding: 10px 38px;
    }
`

const BackgroundColor = styled.div`
    background-color: ${(props) => props.theme.color.tellowPurple};
`
// The layout component reads the background color of the header component and adjusts the color of the navigation bar accordingly.
// so a forward ref has been added to pass the background color to the parent section element above it.

const SupportHeader = React.forwardRef(({ data }, ref) => (
    <BackgroundColor ref={ref}>
        <GridBackground>
            <Overlay>
                <ContentContainer>
                    <Title>{data.title}</Title>
                    <Text dangerouslySetInnerHTML={{ __html: data.text }} />
                    <SignupButton>
                        {data.textCTA} <span className="arrow">{'->'}</span>
                    </SignupButton>
                </ContentContainer>
            </Overlay>
        </GridBackground>
    </BackgroundColor>
))

SupportHeader.displayName = 'SupportHeader'
export default SupportHeader
