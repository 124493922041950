import React from 'react'
import styled from 'styled-components'
import BgImage1 from '../../../images/support/bgImage1.png'
import BgImage2 from '../../../images/support/bgImage2.png'
import BgImage3 from '../../../images/support/bgImage3.png'
import BgImage4 from '../../../images/support/bgImage4.png'
import BgImage5 from '../../../images/support/bgImage5.png'
import BgImage6 from '../../../images/support/bgImage6.png'
import BgImage7 from '../../../images/support/bgImage7.png'
import BgImage8 from '../../../images/support/bgImage8.png'
import BgImage9 from '../../../images/support/bgImage9.png'
import BgImage10 from '../../../images/support/bgImage10.png'
import BgImage11 from '../../../images/support/bgImage11.png'
import BgImage12 from '../../../images/support/bgImage12.png'
import BgImage13 from '../../../images/support/bgImage13.png'
import BgImage14 from '../../../images/support/bgImage14.png'
import BgImage15 from '../../../images/support/bgImage15.png'

const GridContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 400px;
    background-color: ${(props) => props.theme.color.tellowWhite};

    @media (max-width: 450px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        min-height: 500px;
    }

    @media (min-width: 1600px) {
        grid-template-columns: repeat(6, 1fr);
    }
`

const Image = styled.div`
    background-size: cover;
    background-position: center;
    opacity: 0.9;

    &.showOnXl {
        display: none;

        @media (min-width: 1600px) {
            display: block;
        }
    }
`
const GridBackground = ({ children }) => {
    const images = [
        BgImage5,
        BgImage15,
        BgImage3,
        BgImage8,
        BgImage6,
        BgImage10,
        BgImage9,
        BgImage7,
        BgImage12,
        BgImage13,
        BgImage4,
        BgImage14,
        BgImage11,
        BgImage1,
        BgImage2,
        BgImage5,
        BgImage15,
        BgImage3,
    ]

    return (
        <GridContainer>
            {images.map((image, index) => (
                <Image className={index > 14 ? 'showOnXl' : null} key={image} style={{ backgroundImage: `url(${image})` }} />
            ))}
            {children}
        </GridContainer>
    )
}

export default GridBackground
