import React from 'react'
import styled from 'styled-components'
import { Button } from '../../UI'
import Link from '../../UI/Link/link.js'

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        margin: 1rem;
    }
`
const ContentContainer = styled.div`
    margin: 1rem;
`
const DesktopTitle = styled.h1`
    margin-bottom: 1rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    letter-spacing: -0.025em;
    align-items: flex-start;
    color: ${(props) => props.theme.color.tellowPurple};
    @media (max-width: 768px) {
        display: none;
    }
`
const MobileTitle = styled.h1`
    display: none;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: 1.875rem;
        line-height: 2.25rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 600;
        letter-spacing: -0.025em;
        align-items: flex-start;
        color: ${(props) => props.theme.color.tellowPurple};
        display: block;
    }
`

const List = styled.ul`
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    align-items: flex-start;
    margin-left: 1.2rem;
    list-style-type: disc;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`
const PresonalBookkeeperImage = styled.img`
    margin: 0rem;
`
const CompleteButton = styled(Button)`
    padding: 18px 28px;
    @media (max-width: 768px) {
        padding: 10px 38px;
        align-items: center;
        text-align: center;
    }
`
const ButtonContainer = styled.div`
    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
    }
`

const PersonalBookkeeper = ({ data }) => (
    <MainContainer>
        <ContentContainer>
            <DesktopTitle> {data.title}</DesktopTitle>
            <List>
                {data.list.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </List>
            <ButtonContainer>
                <Link to="/plan/compleet">
                    <CompleteButton>{data.textCTA}</CompleteButton>
                </Link>
            </ButtonContainer>
        </ContentContainer>
        <div>
            <MobileTitle> {data.title}</MobileTitle>
            <PresonalBookkeeperImage src={data.personalBookkeeperImage} alt="Personal bookkeeper" />
        </div>
    </MainContainer>
)

export default PersonalBookkeeper
